import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  numeric as rule_numeric,
  max as rule_max,
  min_value as rule_min_value,
} from 'vee-validate/dist/rules'
import pl from 'vee-validate/dist/locale/pl.json'
import dayjs from 'dayjs'
import { validatePolish } from 'validate-polish'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const numeric = extend('numeric', rule_numeric)

export const maxlength = extend('max', rule_max)

export const minValue = extend('min-value', rule_min_value)

export const time = extend('time', {
  validate(value) {
    return value.toString().match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g)
  },
})

export const price = extend('price', {
  validate(value) {
    return value.toString().match(/^[0-9]+(,[0-9]{2})?$/g)
  },
})

export const dateAfter = extend('date-after', {
  params: ['date'],
  validate(value, args) {
    return dayjs(value).isAfter(args.date)
  },
})

export const nip = extend('nip', {
  validate(value) {
    return validatePolish.nip(value)
  },
})

localize({
  pl: {
    messages: {
      ...pl.messages,
      required: 'To pole jest wymagane',
      email: 'Nieprawidłowy adres e-mail',
    },
    names: {},
    fields: {},
  },
})

localize('pl')
